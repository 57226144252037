<template>
  <div class="d-flex flex-column align-center">
    <div>
      <v-img
        :width="imgWidth"
        :class="`d-inline-block ${imgClass}`"
        :src="img"
      />
    </div>
    <div :class="`font-weight-bold ${titleColor} mt-1 mb-2`">
      {{ title }}
    </div>
    <div :class="`text-center body-2 vantagem-text-container ${textColor}`">
      <slot name="text">
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    img: {
      type: String,
      required: true,
    },
    imgWidth: {
      type: String,
      default: '120px',
    },
    imgClass: {
      type: String,
      default: '',
    },
    titleColor: {
      type: String,
      default: 'text--secondary--color',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
};
</script>

<style scoped>
.vantagem-text-container {
  max-width: 280px;
}
</style>
