<template>
  <div>
    <div v-if="showTitle" :class="`headline font-weight-bold ml-2 mb-5 ${titleColor}`">
      {{ title }}
    </div>
    <v-row>
      <v-col cols="12" md="3" offset-md="1">
        <px-vantagem-item :img="imgLocker"
                          title="Segurança"
                          img-width="100px"
                          img-class="mb-5"
                          :title-color="titleColor"
                          :text-color="textColor">
          <template v-slot:text>
            Temos um ambiente seguro com parceiros homologados,
            que <b>garantes a integridade das suas compras</b>.
          </template>
        </px-vantagem-item>
      </v-col>
      <v-col cols="12" md="3">
        <px-vantagem-item :img="imgClock"
                          title="Agilidade"
                          :title-color="titleColor"
                          :text-color="textColor">
          <template v-slot:text>
            Você faz todo o processo de compra <b>sem precisar cotar</b>
            preços ou prazos.
          </template>
        </px-vantagem-item>
      </v-col>
      <v-col cols="12" md="3">
        <px-vantagem-item :img="imgBox"
                          title="Diversidade"
                          :title-color="titleColor"
                          :text-color="textColor">
          <template v-slot:text>
            Aqui você encontra mais de <b>40 mil produtos</b> que atendem todos os setores
            da sua empresa.
          </template>
        </px-vantagem-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VantagemItem from './VantagemItem.vue';
import imgLocker from '../../assets/como-funciona/cadeado.svg';
import imgClock from '../../assets/como-funciona/relogio.svg';
import imgBox from '../../assets/como-funciona/caixas-diversidade.svg';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: 'Vantagens de fechar negócios por aqui',
    },
    titleColor: {
      type: String,
      default: 'text--secondary--color',
    },
    textColor: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      imgLocker,
      imgClock,
      imgBox,
    };
  },
  components: {
    pxVantagemItem: VantagemItem,
  },
};
</script>
