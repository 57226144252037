<template>
  <div>
    <div v-if="showTitle" class="headline font-weight-bold ml-2 mb-3">
      Como funciona?
    </div>
    <v-row>
      <v-col cols="12" sm="6" md="5" offset-md="1">
        <px-como-funciona-item :img="imgPc"
                               title="1 - Faça seu cadastro">
          <template v-slot:text>
            Faça seu <router-link to="/criar-conta">cadastro aqui</router-link>
            e faça parte de uma rede de compradores
            que estão economizando com a Proxpect.
          </template>
        </px-como-funciona-item>
      </v-col>
      <v-col cols="12" sm="6" md="5">
        <px-como-funciona-item :img="imgCart"
                               title="2 - Escolha seus produtos">
          <template v-slot:text>
            Agora você pode escolher os <b>produtos que sua empresa precisa</b>
            e colocar no carrinho.
          </template>
        </px-como-funciona-item>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="5" offset-md="1">
        <px-como-funciona-item :img="imgPayment"
                               title="3 - Forma de pagamento e frete">
          <template v-slot:text>
            Com o carrinho pronto, você pode escolher a forma de pagamento e frete
            <b>por fornecedor</b>.
          </template>
        </px-como-funciona-item>
      </v-col>
      <v-col cols="12" sm="6" md="5">
        <px-como-funciona-item :img="imgPackage"
                               title="4 - Receba seus produtos">
          <template v-slot:text>
            Após o fornecedor aprovar sua compra, seus produtos serão enviados para entrega,
            de acordo com  os prazos e formatos escolhidos.
          </template>
        </px-como-funciona-item>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12" sm="6" md="5" offset-md="1">
        <px-como-funciona-item :img="imgStar"
                               title="5 - Avalie todo o processo">
          <template v-slot:text>
            Ao final de tudo, você poderá avaliar anonimamente todo o processo
            de compra, entrega e qualidade do produto.
          </template>
        </px-como-funciona-item>
      </v-col>
      <v-col cols="12" sm="6" md="5">
        <px-como-funciona-item :img="imgReplay"
                               title="6 - Repita o processo"
                               :show-arrow="false">
          <template v-slot:text>
            Você pode comprar tudo que sua empresa precisa de maneira recorrente, sem depender
            de cotações, de maneira simples e prática.
          </template>
        </px-como-funciona-item>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ComoFuncionaItem from './ComoFuncionaItem.vue';
import imgPc from '../../assets/como-funciona/pc.svg';
import imgCart from '../../assets/como-funciona/carrinho.svg';
import imgPayment from '../../assets/como-funciona/pagamento.svg';
import imgPackage from '../../assets/como-funciona/caixa.svg';
import imgStar from '../../assets/como-funciona/avaliacao.svg';
import imgReplay from '../../assets/como-funciona/replay.svg';

export default {
  props: {
    showTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      imgPc,
      imgCart,
      imgPayment,
      imgPackage,
      imgStar,
      imgReplay,
    };
  },
  components: {
    pxComoFuncionaItem: ComoFuncionaItem,
  },
};
</script>
